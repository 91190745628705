<div class="dashboard-container">
  <!-- <div class="row0">
    <div class="demo-container">
      <div class="demo-text-container">
          <div class="demo-text">This is a demo dashboard. <a class="contact-us" (click)="clickContact()">Contact us</a> with any questions, comments or suggestions.</div>
      </div>
    </div>
  </div> -->
  <div class="row1">
    <div class="health-container card-container">
      <div class="card-header no-header">
        <div class="card-title">
          Your Portfolio Health Check
          <mat-icon matTooltip="A breakdown of all ingredients in your portfolio by sentiment score" class="tooltip-icon">info_outline</mat-icon>
        </div>
      </div>
      <div class="card-content">
        <div class="health-metrics-container">
          <div class="negative-metrics">
            <div class="neg-metric-count">{{numPositiveTotal}}</div>
            <div class="neg-metric-description">Ingredients with <span class="pos-text">POSITIVE</span> scores</div>
            <!-- <div class="spacer"></div>
            <div class="neg-delta">The number of Positive ingredients <span class="pos-text">{{numPositiveTotal>numPositiveLastYearTotal ? 'increased' :'decreased'}}</span> by {{(numPositiveDelta/100).toFixed()}}% compared to the previous year</div> -->
          </div>
          <div class="negative-metrics">
            <div class="neg-metric-count">{{numNegativeTotal}}</div>
            <div class="neg-metric-description">Ingredients with <span class="neg-text">CONCERNING/NEGATIVE</span> scores</div>
            <!-- <div class="spacer"></div>
            <div class="neg-delta">The number of Negative ingredients <span class="neg-text">{{numNegativeTotal>numNegativeLastYearTotal ? 'increased' :'decreased'}}</span> by {{(numNegativeDelta/100).toFixed()}}% compared to the previous year</div> -->
          </div>
          <!-- <div class="negative-metrics">
            <div class="neg-metric-count">{{totalIngredients - numNegativeTotal - numPositiveTotal}}</div>
            <div class="neg-metric-description">Ingredients with <span class="neutral-text">NEUTRAL</span> scores</div>
          </div> -->

        </div>
        <div class="score-bar-container">
          <div
            *ngIf="scoreBarOptions"
            #scoreBar 
            echarts
            [loading]="scoreBarLoading"
            style="height: 20vh; width: 100%;"
            [options]="scoreBarOptions"
          >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row1-1">
    <div class="card-container fast-growing-card-container">
      <div class="card-header">
        <div class="card-title">
          Fast Growing Ingredients
          <mat-icon class="tooltip-icon" matTooltip="Ingredients with the highest growth in related publications/news articles from the previous period">info_outline</mat-icon>
        </div>
      </div>
      <div class="card-content fast-growing-card-content">
        <div class="fast-growing-container">
          <ng-container *ngIf="!fastGrowing">
            <div class="fast-growing-ing skeleton" *ngFor="let fg of [1, 2]">
              <ngx-skeleton-loader></ngx-skeleton-loader>
            </div>
          </ng-container>
          <div class="fast-growing-ing" *ngFor="let fg of fastGrowing">
            <div class="fg-title">
              {{fg.ingredient}}
            </div>
            <div class="fg-text">
              There were {{fg.sum_new | tableValue: 'doc_count'}} new documents concerning {{fg.ingredient}} during the selected period.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row2">
    <div class="ingredient-table-container card-container">
      <div class="card-header">
        <div class="card-title">
          Top Positive Ingredient Effects
          <mat-icon class="tooltip-icon" matTooltip="Ingredients with the most positive science/market sentiment">info_outline</mat-icon>
        </div>
      </div>
      <div class="card-content no-pad">
        <div class="ingredient-table">
          <div class="ingredient-table-header ingredient-row">
            <div>INGREDIENT NAME</div>
            <div>PUBLICATIONS<mat-icon class="help-icon tooltip-icon" matTooltip="Number of publications for this ingredient that exhibit positive sentiment">info_outline</mat-icon></div>
            <div>NEWS ARTICLES <mat-icon class="help-icon tooltip-icon" matTooltip="Number of news articles for this ingredient that exhibit positive sentiment">info_outline</mat-icon></div>
            <div># EFFECTS <mat-icon class="help-icon tooltip-icon" matTooltip="Number of effects associated with this ingredient that are associated with positive sentiment">info_outline</mat-icon></div>
            <div>EFFECT<mat-icon class="help-icon tooltip-icon" matTooltip="Effects associated with this ingredient">info_outline</mat-icon></div>
          </div>
          <ng-container *ngIf="!topPositiveOpportunities" class="skeleton">
            <ngx-skeleton-loader></ngx-skeleton-loader>
            <ngx-skeleton-loader></ngx-skeleton-loader>
            <ngx-skeleton-loader></ngx-skeleton-loader>
          </ng-container>
          <div class="ingredient-row data-row" *ngFor="let o of topPositiveOpportunities; let i = index;">
            <div class="ingredient-label" (click)="clickIngredient(o.ing_id)">{{o.ingredient}}</div>
            <div class="clickable" (click)="clickCell(o, 'science', 'positive')">{{o.doc_count_science_positive | tableValue: 'doc_count'}}</div>
            <div class="clickable" (click)="clickCell(o, 'social','positive')">{{o?.doc_count_social_positive | tableValue: 'doc_count'}}</div>
            <div>{{o?.num_effects | tableValue: 'doc_count'}}</div>
            <div class="effects-container">
              <app-effects-chip-list [eff_ids]="o?.eff_ids" [ing_id]="o.ing_id" [clickable]="true"></app-effects-chip-list>
            </div>
          </div>
        </div> 
      </div>
    </div>
  </div>
  <div class="row3">
    <div class="ingredient-table-container card-container">
      <div class="card-header">
        <div class="card-title">
          Top Negative Ingredient Effects
          <mat-icon class="tooltip-icon" matTooltip="Ingredients with the most negative science/social sentiment">info_outline</mat-icon>
        </div>
      </div>
      <div class="card-content no-pad">
        <div class="ingredient-table">
          <div class="ingredient-table-header ingredient-row">
            <div>INGREDIENT</div>
            <div>PUBLICATIONS <mat-icon class="help-icon tooltip-icon" matTooltip="Number of publications for this ingredient that exhibit negative sentiment">info_outline</mat-icon></div>
            <div>NEWS ARTICLES <mat-icon class="help-icon tooltip-icon" matTooltip="Number of news articles for this ingredient that exhibit negative sentiment">info_outline</mat-icon></div>
            <div># EFFECTS <mat-icon class="help-icon tooltip-icon" matTooltip="Number of effects associated with this ingredient that are associated with negative sentiment">info_outline</mat-icon></div>
            <div>EFFECTS<mat-icon class="help-icon tooltip-icon" matTooltip="Effects associated with this ingredient (Number of negative sentiment science docs for this ingredient & effect/ Number of negative sentiment news articles for this ingredient and effect)">info_outline</mat-icon></div>
          </div>
          <ng-container *ngIf="!topNegativeOpportunities" class="skeleton">
            <ngx-skeleton-loader></ngx-skeleton-loader>
            <ngx-skeleton-loader></ngx-skeleton-loader>
            <ngx-skeleton-loader></ngx-skeleton-loader>
          </ng-container>
          <div class="ingredient-row data-row" *ngFor="let o of topNegativeOpportunities">
            <div class="ingredient-label" (click)="clickIngredient(o.ing_id)">{{o.ingredient}}</div>
            <div class="clickable" (click)="clickCell(o, 'science', 'negative')">{{o.doc_count_science_negative | tableValue: 'doc_count'}}</div>
            <div class="clickable" (click)="clickCell(o, 'social', 'negative')">{{o?.doc_count_social_negative | tableValue: 'doc_Count'}}</div>
            <div>{{o?.num_effects | tableValue: 'doc_count'}}</div>
            <div class="effects-container">
              <app-effects-chip-list [eff_ids]="o?.eff_ids" [ing_id]="o.ing_id" [clickable]="true"></app-effects-chip-list>
            </div>
          </div>
        </div> 
      </div>
    </div>
  </div>
  <div class="row4">
    <div class="card-container most-cited-card-container">
      <div class="card-header">
        Most Influential Article
        <mat-icon class="tooltip-icon" matTooltip="The most heavily cited article linking ingredients in your portfolio to effects.">info_outline</mat-icon>
      </div>
      <div class="card-content most-cited-card-content">
        <div class="most-cited-container">
          <div class="most-cited-photo-container">
            <img class="most-cited-photo" src="assets/stock1.png">
          </div>
          <div class="most-cited-right-container">
            <div class="most-cited-right-header">
              <div class="most-cited-right-title">
                <div [ngClass]="{'title': true, 'with-link': topDocument?.title?.length > 0}" (click)="clickDoc(topDocument)">
                  {{topDocument?.title}}<mat-icon *ngIf="topDocument?.links?.length">open_in_new</mat-icon>
                </div>
              </div>
              <div class="most-cited-right-subtitle">
                <div class="journal">{{topDocument?.journal ? topDocument?.journal : 'Journal Unknown'}}</div>
                <mat-icon class="most-cited-arrow">arrow_right</mat-icon>
                <div class="date">{{topDocument?.date | date: 'MMMM y' }}</div>
                <mat-icon class="most-cited-arrow">arrow_right</mat-icon>
                <div class="citations">{{topDocument?.citations || 0}} citations</div>
              </div>
              <div class="doc-ings chip-list" *ngIf="topDocument?.ingredients?.length">
                <span class="chip-list-label">Ingredients: </span>
                <app-ing-chip-list [ingredients]="topDocument?.ingredients"></app-ing-chip-list>
              </div>
              <div class="doc-effects chip-list" *ngIf="topDocument?.effects?.length">
                <span class="chip-list-label">Effects: </span>
                <app-effects-chip-list [eff_ids]="topDocument.eff_ids" [clickable]="true"></app-effects-chip-list>
              </div>
            </div>
            <div class="most-cited-right-body">
              <div class="most-cited-abstract" [class.expanded]="abstractExpanded">
                {{topDocument?.abstract}}
              </div>
              <div class="most-cited-abstract-button-container" style="display: flex; justify-content: center;">
                <button mat-raised-button color="primary" (click)="expandAbstract()">{{abstractExpanded ? 'Collapse' : 'Expand'}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>