<div class="summary-container" *ngIf="hpi.selectedIngredient$ | async; let sel;">
  <ng-container *ngLet="hpi.selectedIngredient$ | async; let sel;">
    <div class="ing-title">{{sel?.ingredient | uppercase}}</div>
  </ng-container>

  <div class="summary-timeline-container card-container">
    <div class="card-header">
      <div class="card-title">Report<img matTooltip="Double click on a point to inspect documents" src="assets/info_icon.png" class="info-icon-small"></div>
    </div>
    <div class="card-content">
      <div style="padding-left: 16px; padding-right: 16px;">
      <markdown *ngIf="sel?.report">{{sel?.report}}</markdown>
      <div *ngIf="!sel?.report">No report available</div>
      <!-- <app-doc-timeline [scienceOrSocial]="'both'"></app-doc-timeline> -->
    </div>
  </div>
</div>
