<div class="hpi-container">
  <ng-container *ngLet="hpi.selectedIngredient$ | async as sel;">
    <div class="hpi-sidebar">
      <div class="hpi-sidebar-header-container">
        <div class="hpi-sidebar-title-container">
          <div class="hpi-sidebar-title-wrapper">
            <div class="hpi-sidebar-title-text">INGREDIENTS</div>
            <div class="hpi-sidebar-title-icon-container">
              <mat-icon class="hpi-sidebar-title-icon" matTooltip="Select an ingredient from your portfolio">info_outline</mat-icon>
            </div>
          </div>
        </div>
        <div class="hpi-sidebar-search-container">
          <input class="ingredients-search-input" placeholder="&#xF002; Search Ingredients" style="font-family:Arial, FontAwesome" [(ngModel)]="ingredientInput" (input)="userDidSearchIngredients()">
        </div>
      </div>
      <div class="hpi-sidebar-body">
        <div class="ingredients-list-wrapper">
          <div class="ingredients-list">
            <div class="ingredients-list-header">
              <div class="ingredients-list-header-title">
                All Ingredients
              </div>
              <div class="ingredients-list-sort-wrapper">
                <button class="ing-sort-button" mat-button [matMenuTriggerFor]="ingSort" style="font-family:Arial, FontAwesome">
                  <span style="padding-right: 2px">&#xf160;</span>Sort
                </button>
                <button class="ing-sort-button2" mat-button style="font-family:Arial, FontAwesome" (click)="userDidToggleSortDir()">
                  <mat-icon>swap_vert</mat-icon>
                </button>
                <mat-menu #ingSort="matMenu">
                  <button 
                    mat-menu-item 
                    *ngFor="let so of sortOptions" 
                    class="sort-option" 
                    [class.active]="(sortMethod$ | async) === so.value" 
                    (click)="userDidSelectSortMethod(so.value)"
                  >
                  <mat-icon *ngIf="so.value === (sortMethod$ | async)">check</mat-icon>
                  <span>{{so.label}}</span>
                </button>
                </mat-menu>
              </div>
            </div>
            <ng-container>
              <div [ngClass]="['sidebar-ing', sel?.ing_id === ing.ing_id ? 'active' : 'inactive', 'ing' + ing.science_score]" *ngFor="let ing of filteredIngredients" (click)="userDidSelectIngredient(ing)">
                <div class="sidebar-ing-wrapper">
                  <div class="sidebar-ing-header">
                    <div class="sidebar-ing-header-wrapper">
                      <!-- <div class="ing-name" [matTooltip]="ing.ingredient">{{ing.ingredient | truncate : [20]}}</div> -->
                      <div class="ing-name" [matTooltip]="ing.ingredient">{{ing.ingredient}}</div>
                      <ng-container *ngLet="hpi.favoriteIngredientIds.includes(ing.ing_id) as fav">
                        <ng-container *ngLet="sel?.ing_id === ing.ing_id as isActive">
                          <mat-icon [ngClass]="['ing-star', isActive ? 'active' : 'inactive', fav ? 'favorite' : 'not-favorite']" (click)="toggleFavorite(ing)">
                            {{ fav ? 'star' : 'star_outline'}}
                          </mat-icon>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                  <div class="ing-body">
                    <div class="ing-scores-wrapper">
                      <div class="ing-score">
                        <div class="sb-score-label">Science Score</div>
                        <div class="ing-score-wrapper">
                          <div [ngClass]="['sb-score-value', 'bg' + ing.science_score]">{{ing.science_score}}</div>
                          <div class="sb-score-delta-wrapper" *ngLet="ing?.science_score_delta as delta">
                            <img class="sb-score-delta-arrow" *ngIf="delta < 0" src="assets/delta_pos.png">
                            <img class="sb-score-delta-arrow" *ngIf="delta > 0" src="assets/delta_neg.png">
                            <div class="sb-score-delta" *ngIf="delta !== 0">{{delta < 0 ? delta * -1 : delta}}</div>
                          </div>                      
                        </div>
                      </div>
                      <div class="ing-score">
                        <div class="sb-score-label">Market Score</div>
                        <div class="ing-score-wrapper">
                          <div [ngClass]="['sb-score-value', 'bg' + ing.social_score]">{{ing.social_score}}</div>
                          <div class="sb-score-delta-wrapper" *ngLet="ing?.social_score_delta as delta">
                            <img class="sb-score-delta-arrow" *ngIf="delta < 0" src="assets/delta_pos.png">
                            <img class="sb-score-delta-arrow" *ngIf="delta > 0" src="assets/delta_neg.png">
                            <div class="sb-score-delta" *ngIf="delta !== 0">{{delta < 0 ? delta * -1 : delta}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="hpi-content">
      <div class="content-header">
        <div class="tabs-container">
          <div *ngFor="let link of links"
            [ngClass]="{'navbar-tab': true, 'active': router.url.includes('/' + link.route)}"
            (click)="userDidClickTab(link.route)"
          >
            <div [ngClass]="{'tab-text': true, 'new': link.new}" style="display: flex; align-items: center; gap: 4px; justify-content: center;">
              {{link.label}}
              <mat-chip *ngIf="link.new" color="primary" style="font-size: 10px; height: 16px; padding: 0 4px;">NEW</mat-chip>
            </div>
            <div class="border-box"></div>
          </div>
          <div *ngIf="sel?.report"
            [ngClass]="{'navbar-tab': true, 'active': router.url.includes('/' + 'report')}"
            (click)="userDidClickTab('report')"
          >
            <div [ngClass]="{'tab-text': true, 'new': true}" style="display: flex; align-items: center; gap: 4px; justify-content: center;">
              Report
              <mat-chip color="primary" style="font-size: 10px; height: 16px; padding: 0 4px;">NEW</mat-chip>
            </div>
          </div>
        </div>
        <div class="filters-container">
          <!-- <div class="time-select-container">
            <label class="time-select-label">Human/Animal</label>
            <select class="time-select">
              <option>Human</option>
              <option>Animal</option>
            </select>
          </div> -->
          <div class="time-select-container">
            <label class="time-select-label">Choose Period: </label>
            <select class="time-select" [(ngModel)]="hpi.period">
              <option *ngFor="let periodOption of periodOptions" [value]="periodOption.value">{{periodOption.label}}</option>
            </select>
          </div>
        </div>
        <!-- <mat-form-field class="time-select" appearance="outline">
          <mat-label>Choose Period:</mat-label>
          <mat-select [(ngModel)]="selectedTime" (selectionChange)="userDidSelectTime()">
            <mat-option *ngFor="let timeOption of timeOptions" [value]="timeOption.value">
              {{timeOption.label}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
      <ng-container>
        <div class="select-ingredient" *ngIf="(!sel || !sel?.ingredient) && !hpi.loadingIngredient">
          Select Ingredient from sidebar
        </div>
        <div class="loading-ingredient" *ngIf="hpi.loadingIngredient">
          <mat-progress-spinner mode="indeterminate" class="ing-spinner"></mat-progress-spinner>
        </div>
        <ng-container *ngIf="!!sel">
          <router-outlet></router-outlet>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>