export type TPeriod = 'All' | '1y';
export const PeriodOptions = [
  {label: 'All', value: 'All'},
  {label: 'Last Year', value: '1y'}
];

export type TScienceOrSocial = 'science' | 'social';
export const ScienceOrSocialOptions = [
  {label: 'Science', value: 'science'},
  {label: 'Social', value: 'social'}
];

export type TSentiment = 'positive' | 'negative' | 'neutral' | 'all';
export const SentimentOptions = [
  {label: 'All', value: 'all'},
  {label: 'Positive', value: 'positive'},
  {label: 'Negative', value: 'negative'},
  {label: 'Neutral', value: 'neutral'},
]

export interface IIngredient {
  ing_id: string;
	ingredient: string;
	period: TPeriod;
	new_eff_ids_All: string[];
	new_eff_ids_1m: string[];
	new_eff_ids_1y: string[];
	science_score: number;
	science_score_delta: number;
	doc_count_science_positive: number;
	pct_growth_science_positive: number;
	doc_count_new_science_positive: number;
	doc_count_old_science_positive: number;
	doc_count_science_negative: number;
	pct_growth_science_negative: number;
	doc_count_new_science_negative: number;
	doc_count_old_science_negative: number;
	doc_count_science: number;
	pct_growth_science: number;
	doc_count_new_science: number;
	doc_count_old_science: number;
	social_score: number;
	social_score_delta: number;
	doc_count_social_positive: number;
	pct_growth_social_positive: number;
	doc_count_new_social_positive: number;
	doc_count_old_social_positive: number;
	doc_count_social_negative: number;
	pct_growth_social_negative: number;
	doc_count_new_social_negative: number;
	doc_count_old_social_negative: number;
	doc_count_social: number;
	pct_growth_social: number;
	doc_count_new_social: number;
	doc_count_old_social: number;
	doc_count_total_positive: number;
	pct_growth_total_positive: number;
	doc_count_new_total_positive: number;
	doc_count_old_total_positive: number;
	doc_count_total_negative: number;
	pct_growth_total_negative: number;
	doc_count_new_total_negative: number;
	doc_count_old_total_negative: number;
	doc_count_total: number;
	pct_growth_total: number;
	doc_count_new_total: number;
	doc_count_old_total: number;
  report?: string;
  // tmp vars
  hidden?: boolean;
}
export interface IIngredientBundle {
  bundle_id: string;
  bundle_name: string;
  ingredients: IIngredient[];
  mean_score: number;
  created_at: Date,
  updated_at: Date,
  num_new_effects: number;
}

export interface IHpiDocument {
  doc_id: string;
  title: string;
  abstract: string;
  citations: number;
  journal: string;
  ingredients: string[];
  ing_ids: string[];
  effects: string[];
  eff_ids: string[];
  likes: number;
  sentiment: number;
  replies: number;
  links: string[];
  science_or_social: 'science' | 'social';
  date: any;
  create_at: any;
}


export interface IEffect {
  ing_id?: string;
  ingredient?: string;
  period?: TPeriod;
  doc_count_science_positive?: number;
  doc_count_science_negative?: number;
  science_total_doc_count: number;
  doc_count_social_positive?: number;
  doc_count_social_negative?: number;
  social_total_doc_count: number;
  eff_id: string;
  effect: string;
  definition: string;
  image_link?: string;
}

export interface IScore {
  number_concerns: number;
  science_docs: number;
  science_score: number;
  social_docs: number;
  social_score: number;
  last_year_science_count: number;
  last_year_social_count: number;
}
