import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { GroupsComponent } from './modules/groups/groups.component';
import { IngredientsComponent } from './modules/ingredients/ingredients.component';
import { ScienceComponent } from './modules/ingredients/science/science.component';
import { SocialComponent } from './modules/ingredients/social/social.component';
import { SummaryComponent } from './modules/ingredients/summary/summary.component';
import { SupportComponent } from './modules/support/support.component';
import {MatRadioModule} from '@angular/material/radio';
import { MarkdownModule } from 'ngx-markdown'

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgAisModule } from 'angular-instantsearch';
import { NgxEchartsModule } from 'ngx-echarts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NgLetModule } from '@app/directives/ng-let/ng-let.module';
import { SortKeyValuePipeModule } from './pipes/sort-key-value-pipe/sort-key-value-pipe.module';

import { HpiService } from './core/hpi.service';

import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AuthService } from './core/auth.service';
import { EffectsTableModule } from './modules/effects-table/effects-table.module';
import { DocTimelineModule } from './modules/doc-timeline/doc-timeline.module';
import { EffectTreeMapModule } from './modules/effect-tree-map/effect-tree-map.module';
import { DocTableModule } from './modules/doc-table/doc-table.module';
import { TruncatePipeModule } from './pipes/truncate-pipe/truncate-pipe.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { SortPipeModule } from './pipes/sort-pipe/sort-pipe.module';
import { SummaryScoreComponent } from './modules/summary-score/summary-score.component';
import { NewIngBundleDialogComponent } from './modules/groups/new-ing-bundle-dialog/new-ing-bundle-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GlossaryComponent } from './modules/support/glossary/glossary.component';
import { FaqComponent } from './modules/support/faq/faq.component';
import { SupportTabComponent } from './modules/support/support-tab/support-tab.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CardSkeletonComponent } from './modules/card-skeleton/card-skeleton.component';
import { DocDialogComponent } from './modules/doc-dialog/doc-dialog.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { TableValuePipeModule } from './pipes/table-value/table-value-pipe.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import { EffectsChipListComponent } from './modules/effects-chip-list/effects-chip-list.component';
import { IngChipListComponent } from './modules/ing-chip-list/ing-chip-list.component';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'

import { getApp, initializeApp } from 'firebase/app';
import { provideFirebaseApp } from '@angular/fire/app';
import { provideAuth,getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirestore,getFirestore, connectFirestoreEmulator, enableMultiTabIndexedDbPersistence } from '@angular/fire/firestore';
import { provideFunctions,getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';
import { AppService } from './core/app.service';
import { AuthComponent } from './modules/auth/auth.component';
import { LoginComponent } from './modules/auth/login/login.component';
import { SignupComponent } from './modules/auth/signup/signup.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { IonicModule } from '@ionic/angular';
import { MobilePopupComponent } from './modules/mobile-popup/mobile-popup.component';
import { TrainingComponent } from './modules/support/training/training.component';
import { ReportComponent } from './modules/ingredients/report/report.component';
const USE_EMULATORS = false || environment.backendUrl === 'local';

let resolvePersistenceEnabled: (enabled: boolean) => void;
export const persistenceEnabled = new Promise<boolean>(resolve => {
  resolvePersistenceEnabled = resolve;
});

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    AppComponent,
    DashboardComponent,
    GroupsComponent,
    IngredientsComponent,
    ScienceComponent,
    SocialComponent,
    SummaryComponent,
    SupportComponent,
    SummaryScoreComponent,
    NewIngBundleDialogComponent,
    GlossaryComponent,
    FaqComponent,
    SupportTabComponent,
    CardSkeletonComponent,
    ReportComponent,
    DocDialogComponent,
    EffectsChipListComponent,
    IngChipListComponent,
    MobilePopupComponent,
    TrainingComponent,
    ReportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgAisModule,
    MatRadioModule,
    RouterModule,
    NgLetModule,
    NgxEchartsModule,
    SortKeyValuePipeModule,
    MatPaginatorModule,
    TableValuePipeModule,
    NgxSkeletonLoaderModule,
    EffectsTableModule,
    DocTimelineModule,
    EffectTreeMapModule,
    MarkdownModule,
    DocTableModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatSelectModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    SortPipeModule,
    MatDialogModule,
    TruncatePipeModule,
    MarkdownModule.forRoot(),
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    FontAwesomeModule,
    // AmplifyAuthenticatorModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)), 
    provideAuth(() => {
      const auth = getAuth();
      if (USE_EMULATORS) {
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
      }
      return auth
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (USE_EMULATORS) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
        enableMultiTabIndexedDbPersistence(firestore).then(
          () => resolvePersistenceEnabled(true),
          () => resolvePersistenceEnabled(false)
        )
      }
      return firestore
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (USE_EMULATORS) {
        console.log('using functions emulator')
        connectFunctionsEmulator(functions, 'localhost', 5001)
      }
      return functions
    }),
    IonicModule.forRoot(),

  ],
  entryComponents: [
    DocDialogComponent
  ],
  providers: [
    provideNgxMask(),
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService) => {
        return () => new Promise<void>(async (resolve, reject) => {
          console.log(environment.firebase.projectId)
          // set dark mode
          const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
          if (darkMode) {
            document.body.classList.remove('dark');
            // document.body.classList.toggle('dark', darkMode);
          }
          await appService.appInit();
          console.log('after app service init');
          resolve();
        })
      },
      multi: true,
      deps: [AppService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
