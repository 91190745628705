import { Component, OnInit } from '@angular/core';
import { HpiService } from '@app/core/hpi.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  report: string;
  constructor(
    public hpi: HpiService,
  ) { }

  ngOnInit(): void {
    this.report = this.hpi.selectedIngredient$.getValue()?.report;
  }
}
